
import { Component, Vue } from "vue-property-decorator";
import NotAuthenticatedLayout from "@/layout/NotAuthenticatedLayout.vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import ILoginData from "@/interfaces/ILoginData";
import { namespace } from "vuex-class";
import { Auth } from "@/config/Modules";
import LoginWith from "@/components/login_with/LoginWith.vue";

const AuthModule = namespace(Auth.store);

@Component({
  components: { LoginWith, LoginForm, NotAuthenticatedLayout },
})
export default class Login extends Vue {
  protected name = "Login";

  @AuthModule.Action("login")
  protected loginAction: any;

  @AuthModule.Getter("getValidationErrors")
  protected getValidationErrors: any;

  protected onSubmit(formData: ILoginData) {
    this.loginAction({ data: formData });
  }
}
